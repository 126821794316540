import { useState, useEffect } from 'react'
import axios from 'axios'

const useFetchOutages = url => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url)
        setData(response.data.data.page_builder || [])
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    }

    fetchData()
  }, [url])

  return data
}

export default useFetchOutages

import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

export const Footer = props => {
  const { expanded } = props

  return (
    <footer className={`footer ${expanded ? 'expanded' : ''}`}>
      {expanded && (
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-">
              <ul>
                <li>
                  Commtel Pty Ltd, Level 14, 167 Eagle St Brisbane QLD 4000
                </li>
              </ul>
            </div>

            <div className="col-sm-6 col-md-4">
              <div className="footer__wrap footer__wrap_split footer__wrap_split_menu_column">
                <h4>Legal</h4>

                <ul id="menu-footer-column-two">
                  <li className="menu-item menu-what-you-need-to-know">
                    <Link to="what-you-need-to-know">
                      What you need to know
                    </Link>
                  </li>
                  <li>
                    <Link to="privacy-policy">Officeworks™ Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="hardware-warranty-policy">
                      Hardware &amp; Warranty Policy
                    </Link>
                  </li>
                  <li>
                    <a rel="noopener noreferrer" href="/payment-assistance">
                      Payment Assistance
                    </a>
                  </li>
                  <li>
                    <Link to="">SFOA</Link>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="">
                      Complaints
                    </a>
                  </li>
                  <li>
                    <Link to="speed-information-for-nbn">
                      Speed Information for nbn™
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {!expanded && (
        <div className="container">
          <nav role="navigation">
            <ul>
              <li>
                <Link to="/legal">Legal</Link>
              </li>
              <li>
                <Link to="/online-safety">Online Safety</Link>
              </li>
              <li>
                <a href="/pdf/complaint-handling-policy.pdf" target="_blank">
                  Complaints
                </a>
              </li>
              <li>
                <a href="/payment-assistance">Payment Assistance</a>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
              <li>
                <Link to="/outages">Outages</Link>
              </li>
            </ul>
          </nav>
          <p className="copyright">
            Commtel Pty Ltd, Level 14, 167 Eagle St Brisbane QLD 4000
          </p>
        </div>
      )}
    </footer>
  )
}

Footer.defaultProps = {
  expanded: false,
}

Footer.propTypes = {
  expanded: PropTypes.bool,
}
